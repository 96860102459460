import logo from './images/gradual-transparent-cropped.png';
import { useNavigate } from 'react-router-dom';
const Header = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };
  return (
    <header className='header'>
      <img
        src={logo}
        className='logo'
        alt='gradual goodness logo'
        onClick={handleClick}
      />
    </header>
  );
};

export default Header;
