import { useState } from 'react';
import Header from './Header';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import './SignUpForm.css';
import { useNavigate } from 'react-router-dom';

const SignUpForm = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [email, setEmail] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://1gb8up5rg3.execute-api.ap-southeast-2.amazonaws.com/mailchimpstage/subscribe',
        {
          email_address: email,
          status: 'subscribed',
        }
      );
      if (response.status === 200) {
        navigate('/subscription-success');
      } else {
        console.log('Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error(error);
      console.log('catch error.');
    }
  };
  return (
    <div className='signUpFormContainer'>
      <Header />
      <form id='sign-up-form' className='signUpForm' onSubmit={handleSubmit}>
        <h1 className='signUpHeading'>
          GET ON BOARD WITH EARLY ACCESS AND SAVE
        </h1>
        {isDesktop && (
          <p className='subHeading'>
            Be among the first to experience the snacking revolution that's
            about to take the world by storm! By signing up for early access to
            Gradual Goodness, you'll not only be at the forefront of this
            groundbreaking concept but also enjoy exclusive benefits and
            savings.
          </p>
        )}
        <div className='list'>
          <p className='semiSubHeading'>As an early access member, you'll: </p>
          <ol>
            <li>
              Secure your spot: Guarantee your place in line to be one of the
              first to try our revolutionary snacks that get healthier every
              time you eat them.
            </li>

            <li>
              Enjoy exclusive discounts: Lock in special early bird pricing and
              save big on your first order of Gradual Goodness snacks.
            </li>
            <li>
              Get insider updates: Stay in the loop with behind-the-scenes
              updates, product previews, and launch announcements delivered
              straight to your inbox.
            </li>
            <li>
              Shape the future of snacking: Provide valuable feedback and help
              us refine our products to ensure we're meeting your snacking needs
              and preferences.
            </li>
            <li>
              Join a community of innovators: Become part of a forward-thinking
              community that's passionate about revolutionizing the way we snack
              and prioritize our health.
            </li>
          </ol>
        </div>
        <p>
          Don't miss out on this chance to be at the forefront of the snacking
          revolution. Enter your email address below, and we'll make sure you're
          among the first to know when Gradual Goodness launches. Plus, as a
          token of our appreciation, we'll send you a special early access promo
          code that unlocks even more savings on your first order.
        </p>

        <div className='formContainer'>
          <input
            type='email'
            id='email'
            name='email'
            placeholder='Email Address'
            className='emailInput'
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type='submit' className='submitButton'>
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
};
export default SignUpForm;
