import React from 'react';

const SubscriptionSuccess = () => {
  return (
    <div>
      <h2>Subscription Successful!</h2>
      <p>
        Thank you for subscribing. You will receive updates and special offers
        in your inbox.
      </p>
    </div>
  );
};

export default SubscriptionSuccess;
