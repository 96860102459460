import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import Landing from './Landing';
import SubscriptionSuccess from './SubscriptionSuccess';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/signup' element={<SignUpForm />} />
        <Route path='/subscription-success' element={<SubscriptionSuccess />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
