import './Landing.css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import browniesImage from './images/brownies-optimised.png';
import womanChocolateImage from './images/woman-eating-choc-long-optimised.png';
import womanChocolateMobile from './images/woman-eating-chocolate-landscape-final.png';
import chocolateBarLandscape from './images/box-optimised.jpg';
import eatWellMagazine from './images/EWL_cover.jpg';
import frankieMagazine from './images/frankie-cover.jpg';
import healthyFoodMagazine from './images/healthy-food-cover.jpg';
import Header from './Header';

const Landing = () => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const navigate = useNavigate();
  const goToSignup = () => {
    navigate('/signup');
  };
  return (
    <div className='App'>
      <Header />
      <div className='App-header'>
        {isDesktop && (
          <img
            src={browniesImage}
            className='imageFitContainer desktopImage'
            alt='brownies'
          />
        )}
        {!isDesktop && (
          <img
            src={womanChocolateMobile}
            className={'imageFitContainer mobileImage'}
            alt='woman eating chocolate'
          />
        )}
        <div className='headerText'>
          <h1>Get Healthier with Every Bite, Without Noticing</h1>
          <p className='subHeading'>
            Our unique subscription service provides snacks that get healthier
            over time, so you can indulge without compromising your well-being.
            Join now and treat yourself to guilt-free snacking.
          </p>
          <button onClick={goToSignup}>Get Started</button>
        </div>
        {isDesktop && (
          <img
            src={womanChocolateImage}
            className='imageFitContainer'
            alt='woman eating chocolate'
          />
        )}
      </div>
      <div className='howItWorksContainer'>
        <h2>How Gradual Goodness Works</h2>
        <div className='explainerContainer'>
          <p className='explainerText'>
            <strong>
              Our monthly subscription boxes are filled with a variety of
              delicious snacks of your choosing prepared by some of the worlds
              best chefs.
            </strong>{' '}
            .
          </p>
          <p className='explainerText'>
            <strong>
              Each month we ever so slightly reduce the sugar content in each of
              your snacks.
            </strong>{' '}
            But so gradually that you don't even notice the change!
          </p>
          <p className='explainerText'>
            <strong>
              Before you know it, you're enjoying healthy sugar-free snacks
            </strong>{' '}
            without compromising on taste or satisfaction.
          </p>
        </div>
        <p className='noSubscription'>
          <strong>No subscription required.</strong> Try Gradual Goodness once
          or set up automatic deliveries
        </p>
      </div>
      <div className='imageBackgroundContainer'>
        <img src={chocolateBarLandscape} alt='chocolate bar' />
        <div className='overlay'>
          <h2>More Than A Monthly Box</h2>
          <p>
            {isDesktop &&
              `We believe life should happen on your terms, and sometimes you don't
            need a box every month.`}{' '}
            Our service works in your favour, on any schedule you choose:
            bi-weekly, monthly, every 3 months, or on demand - it's truly up to
            you.
          </p>
        </div>
      </div>
      <div className='QandAContainer'>
        <span>Q - </span>
        <div>
          <h3>
            How does Gradual Goodness ensure that the snacks get healthier
            without compromising on taste?
          </h3>
          <p>
            Our team of expert nutritionists and food scientists work tirelessly
            to develop recipes that gradually reduce sugar, fat, and other
            unhealthy ingredients while incorporating healthier alternatives. We
            use cutting-edge food science and rigorous taste-testing to ensure
            that each iteration of our snacks is just as delicious as the last,
            even as they become increasingly healthier.
          </p>
        </div>

        <span>Q - </span>
        <div>
          <h3>
            Will I be able to notice the gradual changes in the snacks'
            healthiness
          </h3>
          <p>
            The beauty of our gradual improvement approach is that the changes
            are so subtle, you likely won't even notice them! We make
            incremental adjustments to our recipes with each delivery, ensuring
            that your taste buds adapt gradually. This means you'll be enjoying
            healthier snacks without any sudden or drastic changes in flavor or
            texture.
          </p>
        </div>

        <span>Q - </span>
        <div>
          <h3>
            How can I trust that Gradual Goodness snacks are actually getting
            healthier?
          </h3>
          <p>
            Transparency is one of our core values at Gradual Goodness. We
            provide detailed nutritional information for each snack in every
            delivery, so you can see exactly how the ingredients and nutritional
            profiles are improving over time.
          </p>
        </div>

        <span>Q - </span>
        <div>
          <h3>What Are My Options For Scheduling A Box?</h3>
          <p>
            Aside from ordering on demand, we have four automatic delivery
            options to choose from when you're in need of a fix:
          </p>
        </div>
        <span>Q - </span>
        <div>
          <h3>Do I Need To Subscribe To Gradual Goodness?</h3>
          <p>Nope. You can sign up for automatic deliveries-or not!</p>
        </div>
        <span>Q - </span>
        <div>
          <h3>What Does Gradual Goodness Cost?</h3>
          <p>
            We have different tiered boxes for as little as $39. You can
            customise it depending on your needs.
          </p>
        </div>
      </div>
      <div className='inTheNewsContainer'>
        <h2>Gradual Goodness In The News</h2>
        <div className='newsArticles'>
          <div className='newsArticle'>
            <img src={eatWellMagazine} alt='eat well magazine' />
            <p>
              "Gradual Goodness is revolutionising the way we think about
              healthy snacking. "
            </p>
          </div>
          <div className='newsArticle'>
            <img src={frankieMagazine} alt='frankie magazine' />
            <p>
              "... the perfect solution for anyone who wants to eat healthier
              but struggles to stick to a strict diet."
            </p>
          </div>
          <div className='newsArticle'>
            <img src={healthyFoodMagazine} alt='healthy food magazine' />
            <p>
              "Their innovative approach to improving the nutritional content of
              snacks over time is a game-changer"
            </p>
          </div>
        </div>
      </div>
      <div className='testimonialContainer'>
        <h2>Hear It From Our Customers</h2>
        <p>
          Don't take our word for it. Hear what our Early Access Customers are
          saying
        </p>
        <div className='testimonials'>
          <div className='testimonial'>
            <p>
              "I can honestly say that Gradual Goodness has changed my life. I
              used to have a major sweet tooth, but now I can't even imagine
              going back to the way I used to eat. The snacks are delicious, and
              the gradual changes are so subtle that I barely even notice them.
              I feel healthier, more energetic, and happier than ever before."
            </p>
            <p>- Sarah, Adelaide</p>
          </div>
          <div className='testimonial'>
            <p>
              "As a busy mum, I've always struggled to find healthy snacks that
              my kids will actually eat. Gradual Goodness has been a
              game-changer for our family. The snacks are so tasty that my kids
              don't even realise they're healthy, and I love that I can trust
              the ingredients. Plus, the subscription service makes my life so
              much easier. I can't recommend Gradual Goodness enough!"
            </p>
            <p>- Emily, Canberra</p>
          </div>
        </div>
      </div>
      <div className='ctaContainer'>
        <h2>Ready to start snacking healthier?</h2>
        <button className='getStartedButton' onClick={goToSignup}>
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Landing;
